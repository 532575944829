/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
// eslint-disable-next-line react/no-danger
import * as React from 'react';
import { Image } from '../../../ui/image';

const InfoBox = ({ downloads, contact }: { downloads: any; contact: any }) => {
  return (
    <section className="container is-centered press-info has-text-centered">
      <div className="columns is-centered">
        <div className="is-6 column">
          <h3>{downloads.title}</h3>
          {Object.keys(downloads.links).map((key) => {
            const { title, url } = downloads.links[key];
            return (
              <a
                key={key}
                href={url}
                download
                target="_blank"
                className="download_link"
                rel="noreferrer"
              >
                {title}
                <Image
                  name="arrow_down.svg"
                  className="inline-svg"
                  alt="arrow"
                />
              </a>
            );
          })}
        </div>
        <div className="is-6 column">
          <h3>PRESSEKONTAKT</h3>
          <span style={{ width: '100%', float: 'left' }} className="name">
            {contact.person && contact.person}
          </span>
          <a
            href={contact.email && `mailto:${contact.email}`}
            className="hidden"
          >
            {contact.email && contact.email}
          </a>
          <a href={contact.tel && `tel:${contact.tel}`}>
            {contact.tel && contact.tel}
          </a>
        </div>
      </div>
    </section>
  );
};

export { InfoBox };
