/* eslint-disable no-param-reassign */
import * as React from 'react';
import { FC, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizedPageContext, IColumnList } from '../utils/types';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import { HeroBackgroundColor } from '../components/partials/common/hero';
import SectionCardSlider from '../components/sections/news-cards-slider-section';
import NewsSection from '../components/sections/news-section';
import { InfoBox } from '../components/partials/common/boxes/InfoBox';

const Page: FC<{
  pageContext: LocalizedPageContext;
}> = ({ pageContext }) => {
  const { t } = useTranslation('press');
  const [loadingError, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [heroTitle, setTitle] = useState('');
  const [ctaUrl, setCtaUrl] = useState('');
  const [articles, setArticles] = useState([]);
  const [articlesExt, setArticlesExt] = useState([]);

  useEffect(() => {
    fetch('https://blog.shore.com/wp-json/wp/v2/posts?categories=560')
      .then((res) => res.json())
      .then(
        async (result) => {
          console.log(result[0].featured_media);
          setCtaUrl(result[0].link);
          setTitle(result[0].title.rendered);

          const cards = await Promise.all(
            result.map(async (item) => {
              const {
                title,
                excerpt,
                date,
                link,
                yoast_head_json,
                jetpack_featured_media_url,
              } = item;

              const imgRes = await fetch(
                `https://blog.shore.com/wp-json/wp/v2/media/${item.featured_media}`
              );
              const imgData = await imgRes.json();
              const img = imgData.source_url;
              const card = {
                title: yoast_head_json.title,
                date: new Date(date).toLocaleDateString('de-DE', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }),
                text: excerpt.rendered,
                link_text: '',
                url: link,
                image: img,
              };
              return card;
            })
          );

          setArticles(cards);
          setIsLoaded(true);
        },
        // eslint-disable-next-line no-shadow
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  useEffect(() => {
    fetch('https://pr-page-55440.firebaseio.com/articles.json')
      .then((res) => res.json())
      .then(
        (result) => {
          result.shift();
          setArticlesExt(result);
          setIsLoaded(true);
        },
        // eslint-disable-next-line no-shadow
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  const { lang, key, alternateUrls } = pageContext;

  const downloads: any = t('downloads', {
    returnObjects: true,
  });
  const pressContact: any = t('pressContact', {
    returnObjects: true,
  });

  return (
    <Layout
      pageContext={pageContext}
      navClasses="transparent"
      mainClasses="overlay_nav press"
    >
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
      />
      <HeroBackgroundColor
        bgColor="black"
        title={heroTitle}
        subtitle="Latest News"
        ctaUrl={ctaUrl}
        ctaText="Mehr lesen"
      />
      <InfoBox downloads={downloads} contact={pressContact} />
      <SectionCardSlider slides={articles} title={t('article.title')} />
      <NewsSection
        news={articlesExt}
        classes="news"
        title={t('externalArticle.title')}
      />
    </Layout>
  );
};

export default Page;
