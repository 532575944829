import * as React from 'react';
import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';

import { FullWidthNewsSlider } from '../partials/common/elements/Slider';
import { CardFloatNews } from '../partials/common/elements/Card';

const SectionCardSlider: FC<{
  slides: any;
  classes?: string;
  title: string;
}> = ({ title, slides, classes }) => {
  console.log(slides);
  return (
    <section
      className={`${
        classes && classes
      } section section-slider-cards news-slider-section `}
    >
      <div className="container ">
        <div className="columns is-centered">
          <div className="column is-12 ">
            <h2 className="title title-large has-text-centered text-black">
              {title}
            </h2>
          </div>
        </div>
      </div>
      <div className="row">
        <FullWidthNewsSlider slides={slides} />
      </div>
    </section>
  );
};

SectionCardSlider.defaultProps = {
  classes: ``,
};
export default SectionCardSlider;
