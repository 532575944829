import * as React from 'react';
import { FC } from 'react';
import Badge from '../partials/common/elements/Badge';

const NewsSection: FC<{
  title: string;
  classes: string;
  news: any;
}> = ({ title, news, classes }) => {
  return (
    <section className={`${classes} section section-news`}>
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8 ">
            <h2 className="title title-large has-text-centered text-black">
              {title}
            </h2>
          </div>
        </div>
        <div className=" is-centered columns">
          <div className="column is-10 questions ">
            {Object.keys(news).map((key) => {
              return (
                <div key={key} className={`${key} faq_entrie article`}>
                  <Badge className="article_date">{news[key].date}</Badge>
                  <h3 className="article_title">{news[key].title}</h3>
                  <p className="excerpt">{news[key].content}</p>
                  <a href={news[key].link} className="article_link">
                    {news[key].baseLink}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsSection;
